import { useEffect, useState } from 'react';
import { Becca } from 'api';
import Snackbar from 'services/snackbar';
import { useQueryParams } from 'hooks/location';
import { useHistory } from 'react-router-dom';

export function useToggle(initial = false) {
  const [state, setState] = useState(initial);

  const toggle = () => setState(pState => !pState);
  return [state, toggle, setState];
}

export function useParamToggle(param) {
  const params = useQueryParams();
  const [state, setState] = useState(params.get(param) === 'true');
  const history = useHistory();

  const toggle = () => {
    if (params.has(param)) {
      params.delete(param);
      setState(false);
    } else {
      params.set(param, 'true');
      setState(true);
    }
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    setState(params.get(param) === 'true');
  }, [params.get(param)]);

  return [state, toggle, setState];
}

export function useHashTags(query = '') {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    if (!query.length < 3) {
      return;
    }
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      setTags(await Becca.getHashTags(query));
    } catch (e) {
      console.error(e);
      Snackbar.show('something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [query]);

  return [tags, loading, refresh];
}

export function useCategories() {
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      setCategories(await Becca.getCategories());
    } catch (e) {
      console.error(e);
      Snackbar.show('something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return [categories, loading, refresh];
}

export function useUiConfig(name, emptyState = {}) {
  const [data, setData] = useState(emptyState);
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      setData(await Becca.getUiConfig(name));
    } catch (e) {
      console.error(e);
      Snackbar.show('something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [name]);

  return [data, loading, refresh];
}

export function useSeo() {
  return useUiConfig('seo');
}

export function useFont() {
  return useUiConfig('font');
}

export function useTestimonials() {
  return useUiConfig('testimonials');
}

export function useChatSettings() {
  return useUiConfig('chat');
}

export function useExpressCheckoutSettings() {
  return useUiConfig('expressCheckout');
}
export function useShowCouponSettings() {
  return useUiConfig('showCoupon');
} export function useCheckoutFieldsConfig() {
  return useUiConfig('checkoutFlowConfig');
}

export function useOrderCancellationSettings() {
  return useUiConfig('orderCancellation');
}

export function useLayoutConfig() {
  return useUiConfig('layoutConfig');
}
export function useCartRemainders() {
  return useUiConfig('cartRemainder');
}
export function useSaleTimers() {
  return useUiConfig('saleTimers', []);
}

export function useThemeConfig() {
  const [themeConfig, setThemeConfig] = useState({});
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      setThemeConfig(await Becca.getUiConfig('themeConfig'));
    } catch (e) {
      console.error(e);
      Snackbar.show('something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return [themeConfig, setThemeConfig, loading, refresh];
}
