import { useCallback, useEffect, useState } from 'react';
import { Becca } from 'api';
import Snackbar from 'services/snackbar';

export function usePlugins(error = true) {
  const [plugins, setPlugins] = useState(null);
  const [loading, setLoading] = useState(false);
  const refresh = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      setPlugins(await Becca.getPlugins());
    } catch (e) {
      if (error) {
        console.error(e);
        Snackbar.show('Errror Occured', 'error');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [plugins, refresh, loading];
}
