import { createContext, useContext } from 'react';

export const UiConfigContext = createContext({
  layoutConfig: {},
  refreshLayoutConfig: () => {},
});

export function useLayoutConfig() {
  return useContext(UiConfigContext).layoutConfig;
}

export function useRefreshLayoutConfig() {
  return useContext(UiConfigContext).refreshLayoutConfig;
}
