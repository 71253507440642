/* eslint-disable no-undef */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import windologo from 'assets/v2/logoFilled.svg';
import { useToggle } from 'hooks/common';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import WebView from 'services/webview';
import { Becca } from 'api';
import SnackBar from 'services/snackbar';
import Loader from 'services/loader';
// import { styled } from '@material-ui/styles';
import starlogo from 'assets/logos/starlogoios.svg';
import starlogoFilled from 'assets/logos/starlogosiosfilled.svg';
// import StarIcon from '@material-ui/icons/Star';
import styles from './ReviewProductIos.module.css';
import ReviewSuccessIos from './ReviewSuccessIos';

function ReviewProductIos({ onClose }) {
  const [rating, setRating] = useState(0);
  const [submit, toggleSubmit] = useToggle();

  const onSetRating = (e) => {
    const value = +e.target.value;
    setRating(value);
  };

  const showNotNow = rating === 0;
  const onNotNow = () => {
    onClose();
  };

  const onSubmit = async () => {
    if (rating === 0) {
      return;
    }
    if (rating > 3) {
      WebView.askRating('store');
      Becca.rateShop({
        rated: true,
        rating,
        platform: 'ios',
      });
      onClose();
      return;
    }
    try {
      Loader.show();
      await Becca.rateShop({
        rated: true,
        rating,
        platform: 'ios',
      });
    } catch (e) {
      SnackBar.showError(e);
      return;
    } finally {
      Loader.hide();
    }
    toggleSubmit();
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className={styles.dialog}
      classes={{ paper: styles.dialog1 }}
    >
      <div
        className={cx(styles.container, {
          [styles.noBottom]: rating > 0
        })}>
        {!submit
          ? (
            <>
              <img src={windologo} alt="" className={styles.emojilogo} />
              <div className={styles.texts}>
                <div className={cx(styles.text, 'bold')}>Enjoying Windo?</div>
                <div className={styles.text}>Tap a star to rate  the</div>
                <div className={styles.text}>App.</div>
              </div>
              <div className={styles.rating}>
                <Rating
                  value={rating}
                  size="large"
                  onChange={onSetRating}
                  classes={{
                    iconFilled: styles.iosRating,
                    iconEmpty: styles.iosRating1
                  }}
                  emptyIcon={<img width="30px" src={starlogo} alt="" />}
                  icon={<img width="30px" src={starlogoFilled} alt="" />}
                />
              </div>
              <div>
                {showNotNow ? (
                  <div className={styles.notNowButton}>
                    <Clickable
                      dataId="reviewproductios-notnow"
                      className={styles.notNowText}
                      // eslint-disable-next-line no-undef
                      onClick={onNotNow}
                    >
                      Not Now
                    </Clickable>
                  </div>
                ) : (
                  <div className={styles.buttons}>
                    <div className={styles.cancelButton}>
                      <Clickable
                        dataId="reviewproductios-cancel"
                        className={styles.cancelText}
                        onClick={onClose}
                      >
                        Cancel
                      </Clickable>
                    </div>
                    <div className={styles.submitButton}>
                      <Clickable
                        dataId="reviewproductios-submit"
                        className={styles.submitText}
                        onClick={onSubmit}
                      >
                        Submit
                      </Clickable>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : <ReviewSuccessIos closeDialog={onClose} />}
      </div>
    </Dialog>
  );
}

ReviewProductIos.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ReviewProductIos;
