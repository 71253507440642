import { connector } from 'api/becca/axios';

export async function listPlans() {
  const {
    data
  } = await connector.get('seller/plans');
  return data;
}

export async function subscribePlan(planId, oneTime = false, isUpgrade = false) {
  const { data } = await connector.post(isUpgrade ? 'seller/plans/upgrade' : 'seller/plans/subscribe', {
    planId,
    oneTime,
  });
  return data;
}

export async function renew() {
  const { data } = await connector.post('seller/plans/renew');
  return data;
}

export async function applyCoupon(id, coupon) {
  const { data } = await connector.post('seller/plans/coupon', {
    id,
    coupon,
  });
  return data;
}

export async function activatePlan(identifier, token, req = {}) {
  const { data } = await connector.post('seller/plans/activate', {
    identifier,
    token,
    data: req,
  });
  return data;
}

export async function getPortal(returnUrl) {
  const { data } = await connector.post('seller/plans/portal', {
    returnUrl
  });
  return data;
}

export async function pollSubscriptionStatus(id) {
  const { data } = await connector.get('seller/plans/subscribe/poll/', {
    params: {
      id,
    }
  });
  return data;
}

export async function startFreeTrail() {
  const { data } = await connector.post('seller/plans/freeTrial');
  return data;
}
