import { connector } from './axios';

export async function getDefaultRoles() {
  const { data } = await connector.get('seller/userManagement/getDefaultRoles');
  return data;
}
export async function getAllUsers(filters) {
  const { data } = await connector.get('seller/userManagement/users', {
    params: filters
  });
  return data;
}
export async function getAllAccounts() {
  const { data } = await connector.get('seller/userManagement/get/accounts');
  return data;
}
export async function getLoginToken(shopId) {
  const { data } = await connector.get(`seller/userManagement/getLoginToken/${shopId}`);
  return data;
}

export async function deleteUser(id) {
  const { data } = await connector.post(`seller/userManagement/delete/${id}`);
  return data;
}
export async function resendInvite(email) {
  const { data } = await connector.post(`seller/userManagement/${email}/resendInvite`);
  return data;
}
export async function updateUserStatus(id, body) {
  const { data } = await connector.patch(`seller/userManagement/updateStatus/${id}`, body);
  return data;
}
export async function inviteUser(values) {
  const { data } = await connector.post('seller/userManagement/invite', values);
  return data;
}
export async function updateUser(id, body) {
  const { data } = await connector.patch(`seller/userManagement/update/${id}`, body);
  return data;
}
export async function updateRole(id, payload) {
  const {
    data
  } = await connector.patch(`seller/userManagement/updateRole/${id}`, payload);
  return data;
}
export async function createRole(payload) {
  const {
    data
  } = await connector.post('seller/userManagement/createRole', payload);
  return data;
}
