import { useCallback, useEffect, useState } from 'react';
import { Becca } from 'api';
import Snackbar from 'services/snackbar';

export function useGetAllUserRoles() {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const data = await Becca.getDefaultRoles();
      setRoles(data?.users);
    } catch (e) {
      console.error(e);
      Snackbar.show('Error Occured', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [roles, refresh, loading];
}

export function useGetAllUsers(filters) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const data = await Becca.getAllUsers(filters);
      setUsers(data);
    } catch (e) {
      console.error(e);
      Snackbar.show('Error Occured', 'error');
    } finally {
      setLoading(false);
    }
  }, [filters, setUsers, setLoading, loading]);

  useEffect(() => {
    refresh();
  }, [JSON.stringify(filters)]);

  return [users, refresh, loading];
}

export function useGetAllAccounts() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const data = await Becca.getAllAccounts();
      setAccounts(data);
    } catch (e) {
      console.error(e);
      Snackbar.show('Error Occured', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [accounts, refresh, loading];
}
