import _ from 'lodash';
import { CountriesAndUnicodes } from './countriesDump';
// import { getParamByISO } from 'iso-country-currency';

export const getCountries = () => CountriesAndUnicodes;

const getISO = () => {
  const all = getCountries()
    .map(x => ({
      [x.iso]: x.countryName
    }));
  return _.reduce(all, (memo, current) => _.assign(memo, current), {});
};

export const countryMap = Object.freeze(getISO());

export const inverseCountryMap = _.invert(countryMap);

export const getCurrencyByCountry = country => getCountries()
  .find(x => x.countryName.toLowerCase() === country)?.currency || 'INR';

export const getCurrencySymbolByCountry = country => {
  const value = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: getCurrencyByCountry(country),
  }).format(1)
    .charAt(0);
  return value;
};

export const isStripeAllowed = (country) => getCountries()
  .filter(x => x.stripeAllowed)
  .map(x => x.countryName)
  .includes(country?.toLowerCase());

export const isPayPalAllowed = (country) => getCountries()
  .filter(x => x.paypalAllowed)
  .map(x => x.countryName)
  .includes(country?.toLowerCase());

const flutterWaveSupported = ['NGN', 'GHS', 'ZAR', 'ARS', 'BRL', 'GBP', 'CAD', 'CVE', 'CLP', 'COP',
  'CDF', 'EGP', 'EUR', 'GMD', 'GNF', 'KES', 'LRD', 'MWK', 'MXN', 'MAD', 'MZN', 'RWF', 'SLL', 'STD',
  'TZS', 'UGX', 'XAF', 'XOF', 'ZMK'];

export const isFlutterWaveAllowed = (currency) => flutterWaveSupported.includes(currency?.toUpperCase());

export const isPayStackAllowed = (currency) => ['GHS', 'KES', 'NGN', 'TZS', 'UGX', 'ZAR']
  .includes(currency?.toUpperCase());

export const MIDTRANS_SUPPORTED_CURRENCY = new Set(['IDR']);

export const MONCASH_SUPPORTED_CURRENCY = new Set(['HTG']);

export const PAYTM_SUPPORTED_CURRENCY = new Set(['INR']);

export const PHONEPE_SUPPORTED_CURRENCY = new Set(['INR']);

export const AIRTEL_MONEY_SUPPORTED_CURRENCY = new Set(['UGX']);

export const isMonCashAllowed = (currency) => MONCASH_SUPPORTED_CURRENCY
  .has(currency?.toUpperCase());

export const isMidtransAllowed = (currency) => MIDTRANS_SUPPORTED_CURRENCY
  .has(currency?.toUpperCase());

export const isPaytmAllowed = (currency) => PAYTM_SUPPORTED_CURRENCY
  .has(currency?.toUpperCase());

export const isPhonepeAllowed = (currency) => PHONEPE_SUPPORTED_CURRENCY
  .has(currency?.toUpperCase());

export const isAirtelMoneyAllowed = (currency) => AIRTEL_MONEY_SUPPORTED_CURRENCY
  .has(currency?.toUpperCase());

export const allowesPaymentMethods = (shop) => {
  if (shop.country === 'India') {
    return true;
  }
  if (isStripeAllowed(shop?.country) || isPayPalAllowed(shop?.country) || isFlutterWaveAllowed(shop?.currency)
    || isPayStackAllowed(shop.currency) || isMonCashAllowed(shop?.currency)
    || isMidtransAllowed(shop?.currency) || isPaytmAllowed(shop?.currency) || isPhonepeAllowed(shop?.currency)) {
    return true;
  }
  return false;
};
