export const CountriesAndUnicodes = [
  {
    iso: 'IN',
    label: 'India',
    iso3: 'IND',
    countryName: 'india',
    currency: 'INR',
    dial: '+91',
    stripeAllowed: true,
    paypalAllowed: true,
  },
  {
    iso: 'US',
    label: 'USA',
    iso3: 'USA',
    countryName: 'usa',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1',
    stripeAllowed: true,
  },
  {
    iso: 'CA',
    label: 'Canada',
    iso3: 'CAN',
    countryName: 'canada',
    currency: 'CAD',
    paypalAllowed: true,
    dial: '+1',
    stripeAllowed: true,
  },
  {
    iso: 'BD',
    label: 'Bangladesh',
    iso3: 'BGD',
    countryName: 'bangladesh',
    currency: 'BDT',
    dial: '+880',
    paypalAllowed: true,
  },
  {
    iso: 'BE',
    label: 'Belgium',
    iso3: 'BEL',
    countryName: 'belgium',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+32',
    stripeAllowed: true,
  },
  {
    iso: 'BF',
    label: 'Burkina Faso',
    iso3: 'BFA',
    countryName: 'burkina faso',
    currency: 'XOF',
    dial: '+226',
    paypalAllowed: true,
  },
  {
    iso: 'BG',
    label: 'Bulgaria',
    iso3: 'BGR',
    countryName: 'bulgaria',
    currency: 'BGN',
    dial: '+359',
    stripeAllowed: true,
    paypalAllowed: true,
  },
  {
    iso: 'BA',
    label: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    countryName: 'bosnia and herzegovina',
    currency: 'BAM',
    dial: '+387',
    paypalAllowed: true,
  },
  {
    iso: 'BB',
    label: 'Barbados',
    iso3: 'BRB',
    countryName: 'barbados',
    currency: 'BBD',
    dial: '+1-246',
    paypalAllowed: true,
  },
  {
    iso: 'WF',
    label: 'Wallis and Futuna',
    iso3: 'WLF',
    countryName: 'wallis and futuna',
    currency: 'XPF',
    dial: '+681',
    paypalAllowed: true,
  },
  {
    iso: 'BL',
    label: 'Saint Barthelemy',
    iso3: 'BLM',
    countryName: 'saint barthelemy',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+590',
  },
  {
    iso: 'BM',
    label: 'Bermuda',
    iso3: 'BMU',
    countryName: 'bermuda',
    currency: 'BMD',
    dial: '+1-441',
    paypalAllowed: true,
  },
  {
    iso: 'BN',
    label: 'Brunei',
    iso3: 'BRN',
    countryName: 'brunei',
    currency: 'BND',
    dial: '+673',
    paypalAllowed: true,
  },
  {
    iso: 'BO',
    label: 'Bolivia',
    iso3: 'BOL',
    countryName: 'bolivia',
    currency: 'BOB',
    dial: '+591',
    paypalAllowed: true,
  },
  {
    iso: 'BH',
    label: 'Bahrain',
    iso3: 'BHR',
    countryName: 'bahrain',
    currency: 'BHD',
    dial: '+973',
    paypalAllowed: true,
  },
  {
    iso: 'BI',
    label: 'Burundi',
    iso3: 'BDI',
    countryName: 'burundi',
    currency: 'BIF',
    dial: '+257',
    paypalAllowed: true,
  },
  {
    iso: 'BJ',
    label: 'Benin',
    iso3: 'BEN',
    countryName: 'benin',
    currency: 'XOF',
    dial: '+229',
    paypalAllowed: true,
  },
  {
    iso: 'BT',
    label: 'Bhutan',
    iso3: 'BTN',
    countryName: 'bhutan',
    currency: 'BTN',
    dial: '+975',
    paypalAllowed: true,
  },
  {
    iso: 'JM',
    label: 'Jamaica',
    iso3: 'JAM',
    countryName: 'jamaica',
    currency: 'JMD',
    dial: '+1-876',
    paypalAllowed: true,
  },
  {
    iso: 'BV',
    label: 'Bouvet Island',
    iso3: 'BVT',
    countryName: 'bouvet island',
    currency: 'NOK',
    paypalAllowed: true,
    dial: '+55',
  },
  {
    iso: 'BW',
    label: 'Botswana',
    iso3: 'BWA',
    countryName: 'botswana',
    currency: 'BWP',
    dial: '+267',
    paypalAllowed: true,
  },
  {
    iso: 'WS',
    label: 'Samoa',
    iso3: 'WSM',
    countryName: 'samoa',
    currency: 'WST',
    dial: '+685',
    paypalAllowed: true,
  },
  {
    iso: 'BQ',
    label: 'Bonaire, Saint Eustatius and Saba ',
    iso3: 'BES',
    countryName: 'bonaire, saint eustatius and saba ',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+599',
  },
  {
    iso: 'BR',
    label: 'Brazil',
    iso3: 'BRA',
    countryName: 'brazil',
    currency: 'BRL',
    paypalAllowed: true,
    dial: '+55',
    stripeAllowed: true,
  },
  {
    iso: 'BS',
    label: 'Bahamas',
    iso3: 'BHS',
    countryName: 'bahamas',
    currency: 'BSD',
    dial: '+1-242',
    paypalAllowed: true,
  },
  {
    iso: 'JE',
    label: 'Jersey',
    iso3: 'JEY',
    countryName: 'jersey',
    currency: 'GBP',
    paypalAllowed: true,
    dial: '+44-1534',
  },
  {
    iso: 'BY',
    label: 'Belarus',
    iso3: 'BLR',
    countryName: 'belarus',
    currency: 'BYR',
    dial: '+375',
    paypalAllowed: false,
  },
  {
    iso: 'BZ',
    label: 'Belize',
    iso3: 'BLZ',
    countryName: 'belize',
    currency: 'BZD',
    dial: '+501',
    paypalAllowed: true,
  },
  {
    iso: 'RU',
    label: 'Russia',
    iso3: 'RUS',
    countryName: 'russia',
    currency: 'RUB',
    paypalAllowed: true,
    dial: '+7',
  },
  {
    iso: 'RW',
    label: 'Rwanda',
    iso3: 'RWA',
    countryName: 'rwanda',
    currency: 'RWF',
    dial: '+250',
    paypalAllowed: true,
  },
  {
    iso: 'RS',
    label: 'Serbia',
    iso3: 'SRB',
    countryName: 'serbia',
    currency: 'RSD',
    dial: '+381',
    paypalAllowed: true,
  },
  {
    iso: 'TL',
    label: 'Timor-Leste',
    iso3: 'TLS',
    countryName: 'timor-leste',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+670',
  },
  {
    iso: 'RE',
    label: 'Réunion',
    iso3: 'REU',
    countryName: 'réunion',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+262',
  },
  {
    iso: 'TM',
    label: 'Turkmenistan',
    iso3: 'TKM',
    countryName: 'turkmenistan',
    currency: 'TMT',
    dial: '+993',
    paypalAllowed: true,
  },
  {
    iso: 'TJ',
    label: 'Tajikistan',
    iso3: 'TJK',
    countryName: 'tajikistan',
    currency: 'TJS',
    dial: '+992',
    paypalAllowed: true,
  },
  {
    iso: 'RO',
    label: 'Romania',
    iso3: 'ROU',
    countryName: 'romania',
    currency: 'RON',
    dial: '+40',
    stripeAllowed: true,
    paypalAllowed: true,
  },
  {
    iso: 'TK',
    label: 'Tokelau',
    iso3: 'TKL',
    countryName: 'tokelau',
    currency: 'NZD',
    paypalAllowed: true,
    dial: '+690',
  },
  {
    iso: 'GW',
    label: 'Guinea-Bissau',
    iso3: 'GNB',
    countryName: 'guinea-bissau',
    currency: 'XOF',
    dial: '+245',
    paypalAllowed: true,
  },
  {
    iso: 'GU',
    label: 'Guam',
    iso3: 'GUM',
    countryName: 'guam',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-671',
  },
  {
    iso: 'GT',
    label: 'Guatemala',
    iso3: 'GTM',
    countryName: 'guatemala',
    currency: 'GTQ',
    dial: '+502',
    paypalAllowed: true,
  },
  {
    iso: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    iso3: 'SGS',
    countryName: 'south georgia and the south sandwich islands',
    currency: 'GBP',
    paypalAllowed: true,
    dial: '+500',
  },
  {
    iso: 'GR',
    label: 'Greece',
    iso3: 'GRC',
    countryName: 'greece',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+30',
    stripeAllowed: true,
  },
  {
    iso: 'GQ',
    label: 'Equatorial Guinea',
    iso3: 'GNQ',
    countryName: 'equatorial guinea',
    currency: 'XAF',
    dial: '+240',
    paypalAllowed: true,
  },
  {
    iso: 'GP',
    label: 'Guadeloupe',
    iso3: 'GLP',
    countryName: 'guadeloupe',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+590',
  },
  {
    iso: 'JP',
    label: 'Japan',
    iso3: 'JPN',
    countryName: 'japan',
    currency: 'JPY',
    paypalAllowed: true,
    dial: '+81',
    stripeAllowed: true,
  },
  {
    iso: 'GY',
    label: 'Guyana',
    iso3: 'GUY',
    countryName: 'guyana',
    currency: 'GYD',
    dial: '+592',
    paypalAllowed: true,
  },
  {
    iso: 'GG',
    label: 'Guernsey',
    iso3: 'GGY',
    countryName: 'guernsey',
    currency: 'GBP',
    paypalAllowed: true,
    dial: '+44-1481',
  },
  {
    iso: 'GE',
    label: 'Georgia',
    iso3: 'GEO',
    countryName: 'georgia',
    currency: 'GEL',
    dial: '+995',
    paypalAllowed: true,
  },
  {
    iso: 'GD',
    label: 'Grenada',
    iso3: 'GRD',
    countryName: 'grenada',
    currency: 'XCD',
    dial: '+1-473',
    paypalAllowed: true,
  },
  {
    iso: 'GB',
    label: 'United Kingdom',
    iso3: 'GBR',
    countryName: 'united kingdom',
    currency: 'GBP',
    paypalAllowed: true,
    dial: '+44',
    stripeAllowed: true,
  },
  {
    iso: 'GA',
    label: 'Gabon',
    iso3: 'GAB',
    countryName: 'gabon',
    currency: 'XAF',
    dial: '+241',
    paypalAllowed: true,
  },
  {
    iso: 'SV',
    label: 'El Salvador',
    iso3: 'SLV',
    countryName: 'el salvador',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+503',
  },
  {
    iso: 'GN',
    label: 'Guinea',
    iso3: 'GIN',
    countryName: 'guinea',
    currency: 'GNF',
    dial: '+224',
    paypalAllowed: true,
  },
  {
    iso: 'GM',
    label: 'Gambia',
    iso3: 'GMB',
    countryName: 'gambia',
    currency: 'GMD',
    dial: '+220',
    paypalAllowed: true,
  },
  {
    iso: 'GL',
    label: 'Greenland',
    iso3: 'GRL',
    countryName: 'greenland',
    currency: 'DKK',
    paypalAllowed: true,
    dial: '+299',
  },
  {
    iso: 'GI',
    label: 'Gibraltar',
    iso3: 'GIB',
    countryName: 'gibraltar',
    currency: 'GIP',
    dial: '+350',
    paypalAllowed: true,
  },
  {
    iso: 'GH',
    label: 'Ghana',
    iso3: 'GHA',
    countryName: 'ghana',
    currency: 'GHS',
    dial: '+233',
    paypalAllowed: true,
  },
  {
    iso: 'OM',
    label: 'Oman',
    iso3: 'OMN',
    countryName: 'oman',
    currency: 'OMR',
    dial: '+968',
    paypalAllowed: true,
  },
  {
    iso: 'TN',
    label: 'Tunisia',
    iso3: 'TUN',
    countryName: 'tunisia',
    currency: 'TND',
    dial: '+216',
    paypalAllowed: true,
  },
  {
    iso: 'JO',
    label: 'Jordan',
    iso3: 'JOR',
    countryName: 'jordan',
    currency: 'JOD',
    dial: '+962',
    paypalAllowed: true,
  },
  {
    iso: 'HR',
    label: 'Croatia',
    iso3: 'HRV',
    countryName: 'croatia',
    currency: 'HRK',
    dial: '+385',
    paypalAllowed: true,
  },
  {
    iso: 'HT',
    label: 'Haiti',
    iso3: 'HTI',
    countryName: 'haiti',
    currency: 'HTG',
    dial: '+509',
    paypalAllowed: true,
    stripeAllowed: true,
  },
  {
    iso: 'HU',
    label: 'Hungary',
    iso3: 'HUN',
    countryName: 'hungary',
    currency: 'HUF',
    paypalAllowed: true,
    dial: '+36',
    stripeAllowed: true,
  },
  {
    iso: 'HK',
    label: 'Hong Kong',
    iso3: 'HKG',
    countryName: 'hong kong',
    currency: 'HKD',
    paypalAllowed: true,
    dial: '+852',
    stripeAllowed: true,
  },
  {
    iso: 'HN',
    label: 'Honduras',
    iso3: 'HND',
    countryName: 'honduras',
    currency: 'HNL',
    dial: '+504',
    paypalAllowed: true,
  },
  {
    iso: 'HM',
    label: 'Heard Island and McDonald Islands',
    iso3: 'HMD',
    countryName: 'heard island and mcdonald islands',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+672',
  },
  {
    iso: 'VE',
    label: 'Venezuela',
    iso3: 'VEN',
    countryName: 'venezuela',
    currency: 'VEF',
    dial: '+58',
    paypalAllowed: false,
  },
  {
    iso: 'VA',
    label: 'Vatican City State (Holy See)',
    iso3: 'VAT',
    countryName: 'vatican city state (holy see)',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+379',
  },
  {
    iso: 'PR',
    label: 'Puerto Rico',
    iso3: 'PRI',
    countryName: 'puerto rico',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-787',
  },
  {
    iso: 'PS',
    label: 'Palestinian Territory',
    iso3: 'PSE',
    countryName: 'palestinian territory',
    currency: 'ILS',
    paypalAllowed: true,
    dial: '+970',
  },
  {
    iso: 'PW',
    label: 'Palau',
    iso3: 'PLW',
    countryName: 'palau',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+680',
  },
  {
    iso: 'PT',
    label: 'Portugal',
    iso3: 'PRT',
    countryName: 'portugal',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+351',
    stripeAllowed: true,
  },
  {
    iso: 'SJ',
    label: 'Svalbard and Jan Mayen',
    iso3: 'SJM',
    countryName: 'svalbard and jan mayen',
    currency: 'NOK',
    paypalAllowed: true,
    dial: '+47',
  },
  {
    iso: 'PY',
    label: 'Paraguay',
    iso3: 'PRY',
    countryName: 'paraguay',
    currency: 'PYG',
    dial: '+595',
    paypalAllowed: true,
  },
  {
    iso: 'IQ',
    label: 'Iraq',
    iso3: 'IRQ',
    countryName: 'iraq',
    currency: 'IQD',
    dial: '+964',
    paypalAllowed: true,
  },
  {
    iso: 'PA',
    label: 'Panama',
    iso3: 'PAN',
    countryName: 'panama',
    currency: 'PAB',
    dial: '+507',
    paypalAllowed: true,
  },
  {
    iso: 'PF',
    label: 'French Polynesia',
    iso3: 'PYF',
    countryName: 'french polynesia',
    currency: 'XPF',
    dial: '+689',
    paypalAllowed: true,
  },
  {
    iso: 'PG',
    label: 'Papua New Guinea',
    iso3: 'PNG',
    countryName: 'papua new guinea',
    currency: 'PGK',
    dial: '+675',
    paypalAllowed: true,
  },
  {
    iso: 'PE',
    label: 'Peru',
    iso3: 'PER',
    countryName: 'peru',
    currency: 'PEN',
    dial: '+51',
    paypalAllowed: true,
  },
  {
    iso: 'PK',
    label: 'Pakistan',
    iso3: 'PAK',
    countryName: 'pakistan',
    currency: 'PKR',
    dial: '+92',
    paypalAllowed: true,
  },
  {
    iso: 'PH',
    label: 'Philippines',
    iso3: 'PHL',
    countryName: 'philippines',
    currency: 'PHP',
    paypalAllowed: true,
    dial: '+63',
    stripeAllowed: true,
  },
  {
    iso: 'PN',
    label: 'Pitcairn',
    iso3: 'PCN',
    countryName: 'pitcairn',
    currency: 'NZD',
    paypalAllowed: true,
    dial: '+870',
  },
  {
    iso: 'PL',
    label: 'Poland',
    iso3: 'POL',
    countryName: 'poland',
    currency: 'PLN',
    paypalAllowed: true,
    dial: '+48',
    stripeAllowed: true,
  },
  {
    iso: 'PM',
    label: 'Saint Pierre and Miquelon',
    iso3: 'SPM',
    countryName: 'saint pierre and miquelon',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+508',
  },
  {
    iso: 'ZM',
    label: 'Zambia',
    iso3: 'ZMB',
    countryName: 'zambia',
    currency: 'ZMK',
    dial: '+260',
    paypalAllowed: false,
  },
  {
    iso: 'EH',
    label: 'Western Sahara',
    iso3: 'ESH',
    countryName: 'western sahara',
    currency: 'MAD',
    dial: '+212',
    paypalAllowed: true,
  },
  {
    iso: 'EE',
    label: 'Estonia',
    iso3: 'EST',
    countryName: 'estonia',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+372',
    stripeAllowed: true,
  },
  {
    iso: 'EG',
    label: 'Egypt',
    iso3: 'EGY',
    countryName: 'egypt',
    currency: 'EGP',
    dial: '+20',
    paypalAllowed: true,
  },
  {
    iso: 'CC',
    label: 'Cocos (Keeling) Islands',
    iso3: 'CCK',
    countryName: 'cocos (keeling) islands',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+166',
  },
  {
    iso: 'ZA',
    label: 'South Africa',
    iso3: 'ZAF',
    countryName: 'south africa',
    currency: 'ZAR',
    dial: '+27',
    paypalAllowed: true,
  },
  {
    iso: 'EC',
    label: 'Ecuador',
    iso3: 'ECU',
    countryName: 'ecuador',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+593',
  },
  {
    iso: 'IT',
    label: 'Italy',
    iso3: 'ITA',
    countryName: 'italy',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+39',
    stripeAllowed: true,
  },
  {
    iso: 'VN',
    label: 'Vietnam',
    iso3: 'VNM',
    countryName: 'vietnam',
    currency: 'VND',
    dial: '+84',
    paypalAllowed: true,
  },
  {
    iso: 'SB',
    label: 'Solomon Islands',
    iso3: 'SLB',
    countryName: 'solomon islands',
    currency: 'SBD',
    dial: '+677',
    paypalAllowed: true,
  },
  {
    iso: 'ET',
    label: 'Ethiopia',
    iso3: 'ETH',
    countryName: 'ethiopia',
    currency: 'ETB',
    dial: '+251',
    paypalAllowed: true,
  },
  {
    iso: 'SO',
    label: 'Somalia',
    iso3: 'SOM',
    countryName: 'somalia',
    currency: 'SOS',
    dial: '+252',
    paypalAllowed: true,
  },
  {
    iso: 'ZW',
    label: 'Zimbabwe',
    iso3: 'ZWE',
    countryName: 'zimbabwe',
    currency: 'ZWL',
    dial: '+263',
    paypalAllowed: true,
  },
  {
    iso: 'SA',
    label: 'Saudi Arabia',
    iso3: 'SAU',
    countryName: 'saudi arabia',
    currency: 'SAR',
    dial: '+966',
    paypalAllowed: true,
  },
  {
    iso: 'ES',
    label: 'Spain',
    iso3: 'ESP',
    countryName: 'spain',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+34',
    stripeAllowed: true,
  },
  {
    iso: 'ER',
    label: 'Eritrea',
    iso3: 'ERI',
    countryName: 'eritrea',
    currency: 'ERN',
    dial: '+291',
    paypalAllowed: true,
  },
  {
    iso: 'ME',
    label: 'Montenegro',
    iso3: 'MNE',
    countryName: 'montenegro',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+382',
  },
  {
    iso: 'MD',
    label: 'Moldova',
    iso3: 'MDA',
    countryName: 'moldova',
    currency: 'MDL',
    dial: '+373',
    paypalAllowed: true,
  },
  {
    iso: 'MG',
    label: 'Madagascar',
    iso3: 'MDG',
    countryName: 'madagascar',
    currency: 'MGA',
    dial: '+261',
    paypalAllowed: true,
  },
  {
    iso: 'MF',
    label: 'Saint Martin',
    iso3: 'MAF',
    countryName: 'saint martin',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+590',
  },
  {
    iso: 'MA',
    label: 'Morocco',
    iso3: 'MAR',
    countryName: 'morocco',
    currency: 'MAD',
    dial: '+212',
    paypalAllowed: true,
  },
  {
    iso: 'MC',
    label: 'Monaco',
    iso3: 'MCO',
    countryName: 'monaco',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+377',
  },
  {
    iso: 'UZ',
    label: 'Uzbekistan',
    iso3: 'UZB',
    countryName: 'uzbekistan',
    currency: 'UZS',
    dial: '+998',
    paypalAllowed: true,
  },
  {
    iso: 'MM',
    label: 'Myanmar',
    iso3: 'MMR',
    countryName: 'myanmar',
    currency: 'MMK',
    dial: '+95',
    paypalAllowed: true,
  },
  {
    iso: 'ML',
    label: 'Mali',
    iso3: 'MLI',
    countryName: 'mali',
    currency: 'XOF',
    dial: '+223',
    paypalAllowed: true,
  },
  {
    iso: 'MO',
    label: 'Macau',
    iso3: 'MAC',
    countryName: 'macau',
    currency: 'MOP',
    dial: '+853',
    paypalAllowed: true,
  },
  {
    iso: 'MN',
    label: 'Mongolia',
    iso3: 'MNG',
    countryName: 'mongolia',
    currency: 'MNT',
    dial: '+976',
    paypalAllowed: true,
  },
  {
    iso: 'MH',
    label: 'Marshall Islands',
    iso3: 'MHL',
    countryName: 'marshall islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+692',
  },
  {
    iso: 'MK',
    label: 'Macedonia',
    iso3: 'MKD',
    countryName: 'macedonia',
    currency: 'MKD',
    dial: '+389',
    paypalAllowed: true,
  },
  {
    iso: 'MU',
    label: 'Mauritius',
    iso3: 'MUS',
    countryName: 'mauritius',
    currency: 'MUR',
    dial: '+230',
    paypalAllowed: true,
  },
  {
    iso: 'MT',
    label: 'Malta',
    iso3: 'MLT',
    countryName: 'malta',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+356',
    stripeAllowed: true,
  },
  {
    iso: 'MW',
    label: 'Malawi',
    iso3: 'MWI',
    countryName: 'malawi',
    currency: 'MWK',
    dial: '+265',
    paypalAllowed: true,
  },
  {
    iso: 'MV',
    label: 'Maldives',
    iso3: 'MDV',
    countryName: 'maldives',
    currency: 'MVR',
    dial: '+960',
    paypalAllowed: true,
  },
  {
    iso: 'MQ',
    label: 'Martinique',
    iso3: 'MTQ',
    countryName: 'martinique',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+596',
  },
  {
    iso: 'MP',
    label: 'Northern Mariana Islands',
    iso3: 'MNP',
    countryName: 'northern mariana islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-670',
  },
  {
    iso: 'MS',
    label: 'Montserrat',
    iso3: 'MSR',
    countryName: 'montserrat',
    currency: 'XCD',
    dial: '+1-664',
    paypalAllowed: true,
  },
  {
    iso: 'MR',
    label: 'Mauritania',
    iso3: 'MRT',
    countryName: 'mauritania',
    currency: 'MRO',
    dial: '+222',
    paypalAllowed: true,
  },
  {
    iso: 'IM',
    label: 'Isle of Man',
    iso3: 'IMN',
    countryName: 'isle of man',
    currency: 'GBP',
    paypalAllowed: true,
    dial: '+44-1624',
  },
  {
    iso: 'UG',
    label: 'Uganda',
    iso3: 'UGA',
    countryName: 'uganda',
    currency: 'UGX',
    dial: '+256',
    paypalAllowed: true,
  },
  {
    iso: 'TZ',
    label: 'Tanzania',
    iso3: 'TZA',
    countryName: 'tanzania',
    currency: 'TZS',
    dial: '+255',
    paypalAllowed: true,
  },
  {
    iso: 'MY',
    label: 'Malaysia',
    iso3: 'MYS',
    countryName: 'malaysia',
    currency: 'MYR',
    paypalAllowed: true,
    dial: '+60',
    stripeAllowed: true,
  },
  {
    iso: 'MX',
    label: 'Mexico',
    iso3: 'MEX',
    countryName: 'mexico',
    currency: 'MXN',
    paypalAllowed: true,
    dial: '+52',
    stripeAllowed: true,
  },
  {
    iso: 'IL',
    label: 'Israel',
    iso3: 'ISR',
    countryName: 'israel',
    currency: 'ILS',
    paypalAllowed: true,
    dial: '+972',
  },
  {
    iso: 'FR',
    label: 'France',
    iso3: 'FRA',
    countryName: 'france',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+33',
    stripeAllowed: true,
  },
  {
    iso: 'IO',
    label: 'British Indian Ocean Territory',
    iso3: 'IOT',
    countryName: 'british indian ocean territory',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+246',
  },
  {
    iso: 'SH',
    label: 'Saint Helena',
    iso3: 'SHN',
    countryName: 'saint helena',
    currency: 'SHP',
    dial: '+290',
    paypalAllowed: true,
  },
  {
    iso: 'FI',
    label: 'Finland',
    iso3: 'FIN',
    countryName: 'finland',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+358',
    stripeAllowed: true,
  },
  {
    iso: 'FJ',
    label: 'Fiji',
    iso3: 'FJI',
    countryName: 'fiji',
    currency: 'FJD',
    dial: '+679',
    paypalAllowed: true,
  },
  {
    iso: 'FK',
    label: 'Falkland Islands',
    iso3: 'FLK',
    countryName: 'falkland islands',
    currency: 'FKP',
    dial: '+500',
    paypalAllowed: true,
  },
  {
    iso: 'FM',
    label: 'Micronesia',
    iso3: 'FSM',
    countryName: 'micronesia',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+691',
  },
  {
    iso: 'FO',
    label: 'Faroe Islands',
    iso3: 'FRO',
    countryName: 'faroe islands',
    currency: 'DKK',
    paypalAllowed: true,
    dial: '+298',
  },
  {
    iso: 'NI',
    label: 'Nicaragua',
    iso3: 'NIC',
    countryName: 'nicaragua',
    currency: 'NIO',
    dial: '+505',
    paypalAllowed: true,
  },
  {
    iso: 'NL',
    label: 'Netherlands',
    iso3: 'NLD',
    countryName: 'netherlands',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+31',
    stripeAllowed: true,
  },
  {
    iso: 'NO',
    label: 'Norway',
    iso3: 'NOR',
    countryName: 'norway',
    currency: 'NOK',
    paypalAllowed: true,
    dial: '+47',
    stripeAllowed: true,
  },
  {
    iso: 'NA',
    label: 'Namibia',
    iso3: 'NAM',
    countryName: 'namibia',
    currency: 'NAD',
    dial: '+264',
    paypalAllowed: true,
  },
  {
    iso: 'VU',
    label: 'Vanuatu',
    iso3: 'VUT',
    countryName: 'vanuatu',
    currency: 'VUV',
    dial: '+678',
    paypalAllowed: true,
  },
  {
    iso: 'NC',
    label: 'New Caledonia',
    iso3: 'NCL',
    countryName: 'new caledonia',
    currency: 'XPF',
    dial: '+687',
    paypalAllowed: true,
  },
  {
    iso: 'NE',
    label: 'Niger',
    iso3: 'NER',
    countryName: 'niger',
    currency: 'XOF',
    dial: '+227',
    paypalAllowed: true,
  },
  {
    iso: 'NF',
    label: 'Norfolk Island',
    iso3: 'NFK',
    countryName: 'norfolk island',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+672',
  },
  {
    iso: 'NG',
    label: 'Nigeria',
    iso3: 'NGA',
    countryName: 'nigeria',
    currency: 'NGN',
    dial: '+234',
    paypalAllowed: true,
  },
  {
    iso: 'NZ',
    label: 'New Zealand',
    iso3: 'NZL',
    countryName: 'new zealand',
    currency: 'NZD',
    paypalAllowed: true,
    dial: '+64',
    stripeAllowed: true,
  },
  {
    iso: 'NP',
    label: 'Nepal',
    iso3: 'NPL',
    countryName: 'nepal',
    currency: 'NPR',
    dial: '+977',
    paypalAllowed: true,
  },
  {
    iso: 'NR',
    label: 'Nauru',
    iso3: 'NRU',
    countryName: 'nauru',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+674',
  },
  {
    iso: 'NU',
    label: 'Niue',
    iso3: 'NIU',
    countryName: 'niue',
    currency: 'NZD',
    paypalAllowed: true,
    dial: '+683',
  },
  {
    iso: 'CK',
    label: 'Cook Islands',
    iso3: 'COK',
    countryName: 'cook islands',
    currency: 'NZD',
    paypalAllowed: true,
    dial: '+682',
  },
  {
    iso: 'XK',
    label: 'Kosovo',
    iso3: 'XKX',
    countryName: 'kosovo',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+383',
  },
  {
    iso: 'CI',
    label: 'Ivory Coast',
    iso3: 'CIV',
    countryName: 'ivory coast',
    currency: 'XOF',
    dial: '+225',
    paypalAllowed: true,
  },
  {
    iso: 'CH',
    label: 'Switzerland',
    iso3: 'CHE',
    countryName: 'switzerland',
    currency: 'CHF',
    paypalAllowed: true,
    dial: '+41',
    stripeAllowed: true,
  },
  {
    iso: 'CO',
    label: 'Colombia',
    iso3: 'COL',
    countryName: 'colombia',
    currency: 'COP',
    dial: '+57',
    paypalAllowed: true,
  },
  {
    iso: 'CN',
    label: 'China',
    iso3: 'CHN',
    countryName: 'china',
    currency: 'CNY',
    paypalAllowed: true,
    dial: '+86',
  },
  {
    iso: 'CM',
    label: 'Cameroon',
    iso3: 'CMR',
    countryName: 'cameroon',
    currency: 'XAF',
    dial: '+237',
    paypalAllowed: true,
  },
  {
    iso: 'CL',
    label: 'Chile',
    iso3: 'CHL',
    countryName: 'chile',
    currency: 'CLP',
    dial: '+56',
    paypalAllowed: true,
  },
  {
    iso: 'CC',
    label: 'Cocos Islands',
    iso3: 'CCK',
    countryName: 'cocos islands',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+61',
  },
  {
    iso: 'CG',
    label: 'Republic of the Congo',
    iso3: 'COG',
    countryName: 'republic of the congo',
    currency: 'XAF',
    dial: '+242',
    paypalAllowed: true,
  },
  {
    iso: 'CF',
    label: 'Central African Republic',
    iso3: 'CAF',
    countryName: 'central african republic',
    currency: 'XAF',
    dial: '+236',
    paypalAllowed: true,
  },
  {
    iso: 'CD',
    label: 'Democratic Republic of the Congo',
    iso3: 'COD',
    countryName: 'democratic republic of the congo',
    currency: 'CDF',
    dial: '+243',
    paypalAllowed: true,
  },
  {
    iso: 'CZ',
    label: 'Czech Republic',
    iso3: 'CZE',
    countryName: 'czech republic',
    currency: 'CZK',
    paypalAllowed: true,
    dial: '+420',
    stripeAllowed: true,
  },
  {
    iso: 'CY',
    label: 'Cyprus',
    iso3: 'CYP',
    countryName: 'cyprus',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+357',
    stripeAllowed: true,
  },
  {
    iso: 'CX',
    label: 'Christmas Island',
    iso3: 'CXR',
    countryName: 'christmas island',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+61',
  },
  {
    iso: 'CR',
    label: 'Costa Rica',
    iso3: 'CRI',
    countryName: 'costa rica',
    currency: 'CRC',
    dial: '+506',
    paypalAllowed: true,
  },
  {
    iso: 'CW',
    label: 'Curacao',
    iso3: 'CUW',
    countryName: 'curacao',
    currency: 'ANG',
    dial: '+599',
    paypalAllowed: true,
  },
  {
    iso: 'CV',
    label: 'Cape Verde',
    iso3: 'CPV',
    countryName: 'cape verde',
    currency: 'CVE',
    dial: '+238',
    paypalAllowed: true,
  },
  {
    iso: 'CU',
    label: 'Cuba',
    iso3: 'CUB',
    countryName: 'cuba',
    currency: 'CUP',
    dial: '+53',
    paypalAllowed: true,
  },
  {
    iso: 'SZ',
    label: 'Swaziland',
    iso3: 'SWZ',
    countryName: 'swaziland',
    currency: 'SZL',
    dial: '+268',
    paypalAllowed: true,
  },
  {
    iso: 'SY',
    label: 'Syria',
    iso3: 'SYR',
    countryName: 'syria',
    currency: 'SYP',
    dial: '+963',
    paypalAllowed: true,
  },
  {
    iso: 'SX',
    label: 'Sint Maarten',
    iso3: 'SXM',
    countryName: 'sint maarten',
    currency: 'ANG',
    dial: '+599',
    paypalAllowed: true,
  },
  {
    iso: 'KG',
    label: 'Kyrgyzstan',
    iso3: 'KGZ',
    countryName: 'kyrgyzstan',
    currency: 'KGS',
    dial: '+996',
    paypalAllowed: true,
  },
  {
    iso: 'KE',
    label: 'Kenya',
    iso3: 'KEN',
    countryName: 'kenya',
    currency: 'KES',
    dial: '+254',
    paypalAllowed: true,
  },
  {
    iso: 'SS',
    label: 'South Sudan',
    iso3: 'SSD',
    countryName: 'south sudan',
    currency: 'SSP',
    dial: '+211',
    paypalAllowed: true,
  },
  {
    iso: 'SR',
    label: 'Suriname',
    iso3: 'SUR',
    countryName: 'suriname',
    currency: 'SRD',
    dial: '+597',
    paypalAllowed: true,
  },
  {
    iso: 'KI',
    label: 'Kiribati',
    iso3: 'KIR',
    countryName: 'kiribati',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+686',
  },
  {
    iso: 'KH',
    label: 'Cambodia',
    iso3: 'KHM',
    countryName: 'cambodia',
    currency: 'KHR',
    dial: '+855',
    paypalAllowed: true,
  },
  {
    iso: 'KN',
    label: 'Saint Kitts and Nevis',
    iso3: 'KNA',
    countryName: 'saint kitts and nevis',
    currency: 'XCD',
    dial: '+1-869',
    paypalAllowed: true,
  },
  {
    iso: 'KM',
    label: 'Comoros',
    iso3: 'COM',
    countryName: 'comoros',
    currency: 'KMF',
    dial: '+269',
    paypalAllowed: true,
  },
  {
    iso: 'ST',
    label: 'Sao Tome and Principe',
    iso3: 'STP',
    countryName: 'sao tome and principe',
    currency: 'STD',
    dial: '+239',
    paypalAllowed: true,
  },
  {
    iso: 'SK',
    label: 'Slovakia',
    iso3: 'SVK',
    countryName: 'slovakia',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+421',
    stripeAllowed: true,
  },
  {
    iso: 'KR',
    label: 'South Korea',
    iso3: 'KOR',
    countryName: 'south korea',
    currency: 'KRW',
    dial: '+82',
    paypalAllowed: true,
  },
  {
    iso: 'SI',
    label: 'Slovenia',
    iso3: 'SVN',
    countryName: 'slovenia',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+386',
    stripeAllowed: true,
  },
  {
    iso: 'KP',
    label: 'North Korea',
    iso3: 'PRK',
    countryName: 'north korea',
    currency: 'KPW',
    dial: '+850',
    paypalAllowed: true,
  },
  {
    iso: 'KW',
    label: 'Kuwait',
    iso3: 'KWT',
    countryName: 'kuwait',
    currency: 'KWD',
    dial: '+965',
    paypalAllowed: true,
  },
  {
    iso: 'SN',
    label: 'Senegal',
    iso3: 'SEN',
    countryName: 'senegal',
    currency: 'XOF',
    dial: '+221',
    paypalAllowed: true,
  },
  {
    iso: 'SM',
    label: 'San Marino',
    iso3: 'SMR',
    countryName: 'san marino',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+378',
  },
  {
    iso: 'SL',
    label: 'Sierra Leone',
    iso3: 'SLE',
    countryName: 'sierra leone',
    currency: 'SLL',
    dial: '+232',
    paypalAllowed: true,
  },
  {
    iso: 'SC',
    label: 'Seychelles',
    iso3: 'SYC',
    countryName: 'seychelles',
    currency: 'SCR',
    dial: '+248',
    paypalAllowed: true,
  },
  {
    iso: 'KZ',
    label: 'Kazakhstan',
    iso3: 'KAZ',
    countryName: 'kazakhstan',
    currency: 'KZT',
    dial: '+7',
    paypalAllowed: true,
  },
  {
    iso: 'KY',
    label: 'Cayman Islands',
    iso3: 'CYM',
    countryName: 'cayman islands',
    currency: 'KYD',
    dial: '+1-345',
    paypalAllowed: true,
  },
  {
    iso: 'SG',
    label: 'Singapore',
    iso3: 'SGP',
    countryName: 'singapore',
    currency: 'SGD',
    paypalAllowed: true,
    dial: '+65',
    stripeAllowed: true,
  },
  {
    iso: 'SE',
    label: 'Sweden',
    iso3: 'SWE',
    countryName: 'sweden',
    currency: 'SEK',
    paypalAllowed: true,
    dial: '+46',
    stripeAllowed: true,
  },
  {
    iso: 'SD',
    label: 'Sudan',
    iso3: 'SDN',
    countryName: 'sudan',
    currency: 'SDG',
    dial: '+249',
    paypalAllowed: true,
  },
  {
    iso: 'DO',
    label: 'Dominican Republic',
    iso3: 'DOM',
    countryName: 'dominican republic',
    currency: 'DOP',
    dial: '+1-809',
    paypalAllowed: true,
  },
  {
    iso: 'DM',
    label: 'Dominica',
    iso3: 'DMA',
    countryName: 'dominica',
    currency: 'XCD',
    dial: '+1-767',
    paypalAllowed: true,
  },
  {
    iso: 'DJ',
    label: 'Djibouti',
    iso3: 'DJI',
    countryName: 'djibouti',
    currency: 'DJF',
    dial: '+253',
    paypalAllowed: true,
  },
  {
    iso: 'DK',
    label: 'Denmark',
    iso3: 'DNK',
    countryName: 'denmark',
    currency: 'DKK',
    paypalAllowed: true,
    dial: '+45',
    stripeAllowed: true,
  },
  {
    iso: 'VG',
    label: 'British Virgin Islands',
    iso3: 'VGB',
    countryName: 'british virgin islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-284',
  },
  {
    iso: 'DE',
    label: 'Germany',
    iso3: 'DEU',
    countryName: 'germany',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+49',
    stripeAllowed: true,
  },
  {
    iso: 'YE',
    label: 'Yemen',
    iso3: 'YEM',
    countryName: 'yemen',
    currency: 'YER',
    dial: '+967',
    paypalAllowed: true,
  },
  {
    iso: 'DZ',
    label: 'Algeria',
    iso3: 'DZA',
    countryName: 'algeria',
    currency: 'DZD',
    dial: '+213',
    paypalAllowed: true,
  },
  {
    iso: 'UY',
    label: 'Uruguay',
    iso3: 'URY',
    countryName: 'uruguay',
    currency: 'UYU',
    dial: '+598',
    paypalAllowed: true,
  },
  {
    iso: 'YT',
    label: 'Mayotte',
    iso3: 'MYT',
    countryName: 'mayotte',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+262',
  },
  {
    iso: 'UM',
    label: 'United States Minor Outlying Islands',
    iso3: 'UMI',
    countryName: 'united states minor outlying islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1',
  },
  {
    iso: 'LB',
    label: 'Lebanon',
    iso3: 'LBN',
    countryName: 'lebanon',
    currency: 'LBP',
    dial: '+961',
    paypalAllowed: true,
  },
  {
    iso: 'LC',
    label: 'Saint Lucia',
    iso3: 'LCA',
    countryName: 'saint lucia',
    currency: 'XCD',
    dial: '+1-758',
    paypalAllowed: true,
  },
  {
    iso: 'LA',
    label: 'Laos',
    iso3: 'LAO',
    countryName: 'laos',
    currency: 'LAK',
    dial: '+856',
    paypalAllowed: true,
  },
  {
    iso: 'TV',
    label: 'Tuvalu',
    iso3: 'TUV',
    countryName: 'tuvalu',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+688',
  },
  {
    iso: 'TW',
    label: 'Taiwan',
    iso3: 'TWN',
    countryName: 'taiwan',
    currency: 'TWD',
    paypalAllowed: true,
    dial: '+886',
  },
  {
    iso: 'TT',
    label: 'Trinidad and Tobago',
    iso3: 'TTO',
    countryName: 'trinidad and tobago',
    currency: 'TTD',
    dial: '+1-868',
    paypalAllowed: true,
  },
  {
    iso: 'TR',
    label: 'Turkey',
    iso3: 'TUR',
    countryName: 'turkey',
    currency: 'TRY',
    dial: '+90',
    paypalAllowed: true,
  },
  {
    iso: 'LK',
    label: 'Sri Lanka',
    iso3: 'LKA',
    countryName: 'sri lanka',
    currency: 'LKR',
    dial: '+94',
    paypalAllowed: true,
  },
  {
    iso: 'LI',
    label: 'Liechtenstein',
    iso3: 'LIE',
    countryName: 'liechtenstein',
    currency: 'CHF',
    paypalAllowed: true,
    dial: '+423',
  },
  {
    iso: 'LV',
    label: 'Latvia',
    iso3: 'LVA',
    countryName: 'latvia',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+371',
    stripeAllowed: true,
  },
  {
    iso: 'TO',
    label: 'Tonga',
    iso3: 'TON',
    countryName: 'tonga',
    currency: 'TOP',
    dial: '+676',
    paypalAllowed: true,
  },
  {
    iso: 'LT',
    label: 'Lithuania',
    iso3: 'LTU',
    countryName: 'lithuania',
    currency: 'LTL',
    dial: '+370',
    stripeAllowed: true,
    paypalAllowed: false,
  },
  {
    iso: 'LU',
    label: 'Luxembourg',
    iso3: 'LUX',
    countryName: 'luxembourg',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+352',
    stripeAllowed: true,
  },
  {
    iso: 'LR',
    label: 'Liberia',
    iso3: 'LBR',
    countryName: 'liberia',
    currency: 'LRD',
    dial: '+231',
    paypalAllowed: true,
  },
  {
    iso: 'LS',
    label: 'Lesotho',
    iso3: 'LSO',
    countryName: 'lesotho',
    currency: 'LSL',
    dial: '+266',
    paypalAllowed: true,
  },
  {
    iso: 'TH',
    label: 'Thailand',
    iso3: 'THA',
    countryName: 'thailand',
    currency: 'THB',
    paypalAllowed: true,
    dial: '+66',
  },
  {
    iso: 'TF',
    label: 'French Southern Territories',
    iso3: 'ATF',
    countryName: 'french southern territories',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+262',
  },
  {
    iso: 'TG',
    label: 'Togo',
    iso3: 'TGO',
    countryName: 'togo',
    currency: 'XOF',
    dial: '+228',
    paypalAllowed: true,
  },
  {
    iso: 'TD',
    label: 'Chad',
    iso3: 'TCD',
    countryName: 'chad',
    currency: 'XAF',
    dial: '+235',
    paypalAllowed: true,
  },
  {
    iso: 'TC',
    label: 'Turks and Caicos Islands',
    iso3: 'TCA',
    countryName: 'turks and caicos islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-649',
  },
  {
    iso: 'LY',
    label: 'Libya',
    iso3: 'LBY',
    countryName: 'libya',
    currency: 'LYD',
    dial: '+218',
    paypalAllowed: true,
  },
  {
    iso: 'VA',
    label: 'Vatican',
    iso3: 'VAT',
    countryName: 'vatican',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+379',
  },
  {
    iso: 'VC',
    label: 'Saint Vincent and the Grenadines',
    iso3: 'VCT',
    countryName: 'saint vincent and the grenadines',
    currency: 'XCD',
    dial: '+1-784',
    paypalAllowed: true,
  },
  {
    iso: 'AE',
    label: 'United Arab Emirates',
    iso3: 'ARE',
    countryName: 'united arab emirates',
    currency: 'AED',
    dial: '+971',
    stripeAllowed: true,
    paypalAllowed: true,
  },
  {
    iso: 'AD',
    label: 'Andorra',
    iso3: 'AND',
    countryName: 'andorra',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+376',
  },
  {
    iso: 'AG',
    label: 'Antigua and Barbuda',
    iso3: 'ATG',
    countryName: 'antigua and barbuda',
    currency: 'XCD',
    dial: '+1-268',
    paypalAllowed: true,
  },
  {
    iso: 'AF',
    label: 'Afghanistan',
    iso3: 'AFG',
    countryName: 'afghanistan',
    currency: 'AFN',
    dial: '+93',
    paypalAllowed: true,
  },
  {
    iso: 'AI',
    label: 'Anguilla',
    iso3: 'AIA',
    countryName: 'anguilla',
    currency: 'XCD',
    dial: '+1-264',
    paypalAllowed: true,
  },
  {
    iso: 'VI',
    label: 'U.S. Virgin Islands',
    iso3: 'VIR',
    countryName: 'u.s. virgin islands',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-340',
  },
  {
    iso: 'IS',
    label: 'Iceland',
    iso3: 'ISL',
    countryName: 'iceland',
    currency: 'ISK',
    dial: '+354',
    paypalAllowed: true,
  },
  {
    iso: 'IR',
    label: 'Iran',
    iso3: 'IRN',
    countryName: 'iran',
    currency: 'IRR',
    dial: '+98',
    paypalAllowed: true,
  },
  {
    iso: 'AM',
    label: 'Armenia',
    iso3: 'ARM',
    countryName: 'armenia',
    currency: 'AMD',
    dial: '+374',
    paypalAllowed: true,
  },
  {
    iso: 'AL',
    label: 'Albania',
    iso3: 'ALB',
    countryName: 'albania',
    currency: 'ALL',
    dial: '+355',
    paypalAllowed: true,
  },
  {
    iso: 'AO',
    label: 'Angola',
    iso3: 'AGO',
    countryName: 'angola',
    currency: 'AOA',
    dial: '+244',
    paypalAllowed: true,
  },
  {
    iso: 'AQ',
    label: 'Antarctica',
    iso3: 'ATA',
    countryName: 'antarctica',
    currency: '',
    dial: '+672',
    paypalAllowed: false,
  },
  {
    iso: 'AS',
    label: 'American Samoa',
    iso3: 'ASM',
    countryName: 'american samoa',
    currency: 'USD',
    paypalAllowed: true,
    dial: '+1-684',
  },
  {
    iso: 'AR',
    label: 'Argentina',
    iso3: 'ARG',
    countryName: 'argentina',
    currency: 'ARS',
    dial: '+54',
    paypalAllowed: true,
  },
  {
    iso: 'AU',
    label: 'Australia',
    iso3: 'AUS',
    countryName: 'australia',
    currency: 'AUD',
    paypalAllowed: true,
    dial: '+61',
    stripeAllowed: true,
  },
  {
    iso: 'AT',
    label: 'Austria',
    iso3: 'AUT',
    countryName: 'austria',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+43',
    stripeAllowed: true,
  },
  {
    iso: 'AW',
    label: 'Aruba',
    iso3: 'ABW',
    countryName: 'aruba',
    currency: 'AWG',
    dial: '+297',
    paypalAllowed: true,
  },
  {
    iso: 'AX',
    label: 'Aland Islands',
    iso3: 'ALA',
    countryName: 'aland islands',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+358-18',
  },
  {
    iso: 'AZ',
    label: 'Azerbaijan',
    iso3: 'AZE',
    countryName: 'azerbaijan',
    currency: 'AZN',
    dial: '+994',
    paypalAllowed: true,
  },
  {
    iso: 'IE',
    label: 'Ireland',
    iso3: 'IRL',
    countryName: 'ireland',
    currency: 'EUR',
    paypalAllowed: true,
    dial: '+353',
    stripeAllowed: true,
  },
  {
    iso: 'ID',
    label: 'Indonesia',
    iso3: 'IDN',
    countryName: 'indonesia',
    currency: 'IDR',
    dial: '+62',
    paypalAllowed: true,
  },
  {
    iso: 'UA',
    label: 'Ukraine',
    iso3: 'UKR',
    countryName: 'ukraine',
    currency: 'UAH',
    dial: '+380',
    paypalAllowed: true,
  },
  {
    iso: 'QA',
    label: 'Qatar',
    iso3: 'QAT',
    countryName: 'qatar',
    currency: 'QAR',
    dial: '+974',
    paypalAllowed: true,
  },
  {
    iso: 'MZ',
    label: 'Mozambique',
    iso3: 'MOZ',
    countryName: 'mozambique',
    currency: 'MZN',
    dial: '+258',
    paypalAllowed: true,
  },
];
