import Axios from 'axios';

export async function fetchVersion() {
  const { data } = await Axios.get('/meta.json');
  return data;
}

export async function fetchIp() {
  const { data } = await Axios.get('https://api.ipify.org?format=json');
  return data?.ip;
}

const App = {
  fetchIp,
  fetchVersion,
};

export default App;
