import CONFIG from 'config';
import axios from './client';

const getCountry = async () => {
  const { data } = await axios.get('https://api.bigdatacloud.net/data/ip-geolocation', {
    params: {
      key: CONFIG.BIGDATACLOUD.key,
      localityLanguage: 'en',
    },
  });
  return data?.country ?? {};
};

const getFonts = async () => {
  const { data } = await axios.get('https://www.googleapis.com/webfonts/v1/webfonts', {
    params: {
      key: 'AIzaSyDpcG83q4qSNDY3rD6uIbpUWY-U-OTUmus',
      sort: 'alpha',
      fields: 'items(family),items(category)',
    }
  });

  return data.items;
};

const External = {
  getCountry,
  getFonts,
};

export default External;
