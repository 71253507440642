import { connector as becca } from 'api/becca/axios';
import { connector as factory } from 'api/factory/axios';

const fetchers = {
  becca,
  factory,
};
export function fetcher(url) {
  const [service, ...other] = url.split('/');
  const apiUrl = `/${other.join('/')}`;
  return fetchers[service].get(apiUrl).then((res) => res.data);
}

// const { trigger } = useSWRMutation('becca/', methodFetcher('post')); // post/patch
// const { data } = useSWR('becca/');// get
export const methodFetcher = method => async (url, { arg: req }) => {
  const [service, ...other] = url.split('/');
  const apiUrl = `/${other.join('/')}`;
  const { data } = await fetchers[service][method](apiUrl, req);
  return data;
};
